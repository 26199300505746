import axios from 'axios'
import { API_URL } from '../environment'

// rest client setup...
const clientConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 15000, // 15 seconds
  withCredentials: true
}


const instance = axios.create(clientConfig)

export default instance