import { navigate } from "gatsby"

export const PATHS = {
  root: '/',
  verify: '/verify',
  consents: '/consents'
}

export const navTo = {
  root: () => navigate(PATHS.root),
  verify: () => navigate(PATHS.verify),
  consents:() => navigate(PATHS.consents)
}