import React from "react"
import { Col, Form, InputGroup } from "react-bootstrap"

export const Input = ({ name, value, type = 'text', innerRef: ref, Prepend = null, ...props }) => {
    return <>
        <Form.Group as={Col} controlId={name} className="px-0">
            <InputGroup>
                {Prepend && <Prepend />}
                <Form.Control
                    size="lg"
                    placeholder={`Enter ${name}`}
                    {...{ name, value, type, ref }}
                    {...props} />
            </InputGroup>
        </Form.Group>
    </>
}

export const SimpleInput = ({ name, state, ...props }) => <Input {...{ name, value: state[name], Prepend: prepend(name) }} {...props} />

export const prepend = text => () => <>
    <InputGroup.Prepend>
        <InputGroup.Text className="text-capitalize">{text}</InputGroup.Text>
    </InputGroup.Prepend>
</>

export default Input