import PropTypes from "prop-types"
import React from "react"
import { Navbar } from 'react-bootstrap'
import api from '../utils/API'
import { navTo } from "../utils/navigation"
import cuscalLogo from '../images/myCDR_map_RGB_logo_500x500.svg'

const logout = () => {
  api.logout()
  .then(() => {
    navTo.root()
  })
  .catch( (err) => {
    navTo.root()
    console.error("unable to logout", err)
  })
}

const Header = ({ logoutEnable }) => (
  <Navbar variant="light" sticky="top" className="justify-content-between" style={{ background: '#373737' }}>
    <Navbar.Brand >
      <img
        alt=""
        src={cuscalLogo}
        width="50"
        height="50"
        className="d-inline-block align-center mb-0"
      />
    </Navbar.Brand>
    <Navbar.Text className="text-white">
      <h3 className="m-0 p-0">CDR Consent Dashboard</h3>
    </Navbar.Text>
    <Navbar.Text className={`text-white ${logoutEnable && 'cursor-pointer'}`} onClick={() => logoutEnable && logout()}>
      Logout
    </Navbar.Text>
  </Navbar>
)

Header.propTypes = {
  logoutEnable: PropTypes.bool,
}

Header.defaultProps = {
  logoutEnable: true
}

export default Header
