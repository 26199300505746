import React from 'react'
import styled from 'styled-components'

const FullScreen = styled.div`
  position: fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  background: rgba(0,0,0,0.2);
  z-index: 10000;
`

const Spinner = (props) => {

  const { isLoading = false } = props
  return (
    <>
      {
        isLoading &&
        <FullScreen className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </FullScreen>
      }
    </>
  )
}

export default Spinner