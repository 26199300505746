/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
// import "./layout.css"


const Layout = ({ children, headerLogoutEnable }) => {

  return (
    <>
      <Header logoutEnable={headerLogoutEnable}></Header>
      <main className="mb-5">{children}</main>
      <div className="footer-container fixed-bottom" style={{ background: '#373737', color: '#FFFFFF', fontFamily: 'Arial' }}>
        Please read our <a href="https://www.cuscalpayments.com.au/download/cuscal-cdr-policy/" target="_blank">Consumer Data Rights policy</a> for more information about how Cuscal handles your data.
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerLogoutEnable: PropTypes.bool
}

Layout.defaultProps = {
  headerLogoutEnable: true
}

export default Layout
