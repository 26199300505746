import client from './axios'

const login = (accountId, mobileNum) => {
  return client.post('/login', { accountId, mobileNum })
}

const verifyTotp = (totp) => {
  return client.post('/verifyToken', { totp })
}

const getConsents = () => {
  return client.get('/consents')
}

const getStatus = () => {
  return client.get('/status')
}

const logout = () => {
  return client.get('/logout')
}

const resendToken = () => {
  return client.get('/resendToken')
}

const getConsentTag = (consentId) => {
  return client.get(`/consents/${consentId}/tag`)
}

const setConsentTag = (consentId, data) => {
  return client.post(`/consents/${consentId}/tag`, data)
}

const revokeConsent = (consentId) => {
  return client.patch(`/revoke/consents/${consentId}`)
}
export default {
  login,
  verifyTotp,
  getConsents,
  getStatus,
  resendToken,
  revokeConsent,
  getConsentTag,
  setConsentTag,
  logout
}